import Excel, {
  Cell,
  CellFormulaValue,
  CellHyperlinkValue,
  CellRichTextValue,
  CellSharedFormulaValue,
  Column,
  ValueType,
  Workbook,
  Worksheet,
} from 'exceljs'
import { saveAs } from 'file-saver'
// import { FieldType } from '../fieldType';

export interface IExportOptions {
  fileName?: string
  showSelect?: boolean
  showRequired?: boolean
}

export function trans2ExcelData(data: any, allData: any) {
  if (typeof data === 'string') {
    return data
  } else if (typeof data !== 'object') {
    return data
  } else if (Array.isArray(data)) {
    data = data.filter(c => typeof c !== 'object')
    return data.join(' ')
  } else if (typeof data === 'object') {
    return { ...data }
  } else {
    return ''
  }
}

export function transCell2Data(cell: Cell) {
  const { value, type } = cell
  let result

  switch (type) {
    case ValueType.Hyperlink:
      result = (value as CellHyperlinkValue)?.text
      break
    case ValueType.Formula:
      result = (value as CellFormulaValue)?.result
      break
    case ValueType.RichText:
      result = (value as CellRichTextValue)?.richText
      break
    case ValueType.SharedString:
      result = (value as CellSharedFormulaValue)?.result
      break
    default:
      result = value
      break
  }
  return result
}

export function getExcelColumnsByFormItems(columns: any[]) {
  const excelColumns: Array<Partial<Column>> = []

  columns.forEach(col => {
    if (col.children) {
      // 子表单
      col.children.forEach((c: any) => {
        excelColumns.push({ header: c.title, key: c.dataIndex, width: c.width })
      })
    } else {
      excelColumns.push({ header: col.title, key: col.dataIndex, width: col.width })
    }
  })

  return excelColumns
}

export function getExcelDataByFormItems(columns: any[], dataSource: any[]): any[] {
  return dataSource.map(record => {
    const line: any[] = []
    columns.map(col => {
      let currentData = record[col.dataIndex]

      if (col.children) {
        // 子表单
        col.children.map((c: any) => {
          const cd = record[c.dataIndex || c.key]
          line.push(trans2ExcelData(cd, record))
        })
      } else {
        if (col.customRender) {
          currentData = col.customRender(currentData, record)
        }

        line.push(trans2ExcelData(currentData, record))
      }
    })
    return line
  })
}

// export function addSelect2WorkSheet(workSheet: Worksheet, formItems: any[]) {
//   formItems.forEach((item) => {
//     if (item._type?.type === 'labelValue') {
//       if (!item._type.path) {
//         return;
//       }
//       const labelName = item._type?.labelPath ? Utils.getDataByName(item, item._type?.labelPath) : 'label';
//       const options = Utils.getDataByName(item, item._type.path).map((l: any) => l[labelName]);
//       const formulae = '"' + options.join(',') + '"';
//       const col = workSheet.getColumn(item.name);

//       if (formulae.length > 255) {
//         return;
//       }

//       col?.eachCell?.({ includeEmpty: true }, (cell, i) => {
//         if (i === 1) {
//           return;
//         }
//         cell.dataValidation = {
//           type: 'list',
//           allowBlank: true,
//           formulae: [formulae],
//         };
//       });
//     }
//   });
// }

export function addColumnRequiredColor(workSheet: Worksheet, formItems: any[]) {
  formItems.forEach(item => {
    if (item.required) {
      const cell = workSheet.getRow(1).getCell(item.name)

      cell.font = { color: { argb: 'fff20000' } }
    }
  })
}

export function mergeSheetCells(workSheet: Worksheet, columns: any[], dataSource: any[]) {
  dataSource.forEach((data, i) => {
    columns.forEach(col => {
      const rowSpan = data[(col.dataIndex || col.key) + '_rowSpan'] || data.rowSpan || 0
      if (rowSpan > 1) {
        const colIndex = workSheet.getColumn(col.dataIndex).number
        if (colIndex) {
          // 合并单元格
          workSheet.mergeCells(i + 2, colIndex, i + rowSpan + 1, colIndex)
          // 设置垂直居中
          workSheet.getCell(i + rowSpan + 1, colIndex).alignment = { vertical: 'middle' }
        }
      }
    })
  })
}

export function transSheetByFormItemsAndData(
  workSheet: Worksheet,
  tableColumns: any[],
  dataSource: any[]
) {
  // const tableColumns = FieldType.trans2TreeByType(formItems);

  // 插入表头
  workSheet.columns = getExcelColumnsByFormItems(tableColumns)

  // 插入数据
  workSheet.addRows(getExcelDataByFormItems(tableColumns, dataSource))

  // 合并单元格
  mergeSheetCells(workSheet, tableColumns, dataSource)

  // 添加下拉框
  // if (options?.showSelect) {
  //   addSelect2WorkSheet(workSheet, formItems);
  // }

  // // 必填加红
  // if (options?.showRequired) {
  //   addColumnRequiredColor(workSheet, formItems);
  // }
}

export async function writeFile(workbook: Workbook, fileName: string) {
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer])
  saveAs(blob, fileName || 'export.xlsx')
}

export async function getBlob(workbook: Workbook) {
  if (!workbook) return new Blob([])
  const buffer = await workbook.xlsx.writeBuffer()
  return new Blob([buffer])
}

export function exportXlsx(formItems: any[], dataSource: any[]) {
  const workbook = new Excel.Workbook()
  const sheet = workbook.addWorksheet('sheet1')

  transSheetByFormItemsAndData(sheet, formItems, dataSource)

  return workbook

  // await writeFile(workbook, options.fileName as string);
}

export function createSheet(formItems: any[], dataSource: any[], workbook: any, sheetName: any) {
  const newWorkbook = workbook || new Excel.Workbook()
  const sheet = newWorkbook.addWorksheet(sheetName || 'sheet1')

  transSheetByFormItemsAndData(sheet, formItems, dataSource)

  return newWorkbook
}

function transFile2Buffer(file: File): Promise<any> {
  return new Promise<any>(resolve => {
    const reader = new FileReader()
    reader.onload = function(e: any) {
      let binary = ''
      const bytes = new Uint8Array(e.target.result)

      for (let i = 0; i < bytes.length; ++i) {
        binary += String.fromCharCode(bytes[i])
      }

      resolve(binary as any)
    }
    reader.readAsArrayBuffer(file)
  })
}

export function transWorkSheet2List(workSheet: Worksheet, tableColumns: any[]): any[] {
  const result: any[] = []
  // const tableColumns = FieldType.trans2TreeByType(formItems);
  const sortedTableColumns: any[] = []

  workSheet.getRow(1).eachCell(cell => {
    sortedTableColumns.push(tableColumns.find(col => col.title === cell.value))
  })

  workSheet.eachRow((row, i) => {
    if (i === 1) {
      return
    }
    const record: any = {}
    sortedTableColumns.forEach((col, i) => {
      if (!col) {
        return
      }

      const cell = row.getCell(i + 1)

      let currentData = transCell2Data(cell)

      if (col.reverseRender) {
        currentData = col.reverseRender(currentData)
      }

      record[col.dataIndex || col.key] = currentData
    })
    result.push(record)
  })

  return result
}

export async function importXlsx(file: any, formItems: any[], sheetName?: string) {
  const workBook = new Excel.Workbook()

  const buffer = await transFile2Buffer(file)
  await workBook.xlsx.load(buffer)

  const workSheet = sheetName
    ? workBook.getWorksheet(sheetName)
    : workBook.worksheets.filter(f => f)[0]

  if (workSheet) {
    const list = transWorkSheet2List(workSheet, formItems)
    return list
  }

  return []
}
